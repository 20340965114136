.footer-logo {
  font-size: 3rem;
  margin-top: 20px;


}

footer {
  width: 100vw;
  font-size: 1.2rem;
  background-color: #7489b0;
  background-size: cover;

}

.head-img-2 {
  width: 150px;
  margin-top: 10px;
  height: 150px;
}

.footer-menu {
  padding:20px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

ul {
  margin: 0;
  padding: 0;
}

.footer-head {
  display: flex;
}

.footer-social-logo {
  width: 37px;
  height: 37px;

}

.footer-menu-useful-link {
  margin: 5px;
}

.footer-menu-socialLink {
  margin: 5px;
}

.footer-menu-contact-us {
  margin: 5px;
}

.footer-logo {
  display: flex;
  justify-content: center;
}

.footer-link-headings {
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 5px;
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.footer-social-headings {
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 4px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  margin-bottom: 10px;
  /* margin-left: 45%; */
  color: rgb(255, 255, 255);

}

.footer-contactUs-headings {
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: none;
  padding-bottom: 5px;
  color: rgb(255, 255, 255);
}

.footer-link-list {
  color: rgb(255, 255, 255);
}

.footer-contactUs-link-list {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: rgb(255, 255, 255);
}


.footer-address {
  width: 22vw;
}

.footer-copyright {
 
  background-color: #7489b0;
  border-top: 1px solid rgb(255, 255, 255);
  padding: 10px 0px 10px 0px;
  text-align: center;
  color: rgb(255, 255, 255);
  padding: 15px;
}

.footer-social-link-list {
  text-align: center;
  padding-top: 7px;
  margin-left: 20px;
  margin-right: 20px;
}

.footer-social-link-list li {
  display: inline;
  justify-content: space-around;
  margin: 10px;
}

.footer-links {
  margin-right: 30px;
  
}

li {
  list-style: none;
  margin: 10px;
  font-size: 1rem;
  font-weight: 200;

}


.link{
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.link:hover {
  color: #16427f;
  transition: 0.5sec;
}

.logo {
  width: 25px;
  height: 25px;
}

.footer-iconls-1 {
  display: flex;
}

.icon3 {
  color: #ffffff;
  font-size: 18px;
  /* margin-right: 10px; */
  margin-top: 10px;
}

.icons-1 {
  margin-top: 20px;
}

.footer-adde-main {
  display: flex;
}


@media (max-width: 868px){
 
}

@media (max-width: 768px) {
  .footer-logo {
    width: 100%;
  }

  .footer-social-headings {
    margin-left: 35%;
    font-size: 1.5rem;
  }

  .footer-contactUs-headings,
  .footer-link-headings {
    font-size: 1.5rem;
  }

  .footer-social-link-list {
    width: 95%;
    margin-left: 2%;
  }

  .logo {
    width: 8.567%;
  }

}


@media screen and (max-width: 626px) {
  .footer-menu {
    display: flex;
    flex-direction: column;
  }

  .footer-contactUs-heading {
    margin-left: 0;
    font-weight: bold;
    font-size: 1.5rem;
  }

}

@media (max-width: 480px) {


  .footer-link-headings {
    font-size: 1.5rem;
  }

  .link {
    font-size: 18px;
  }

  .footer-text1 {
    font-size: 18px;
  }

}

.call-btn {
  z-index: 9999;
  height: 58px;
  width: 58px;
  position: fixed;
  right: 21px;
  bottom: 20px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  border: none;
  color: rgb(22 20 102);
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 1px 1px 10px 1px grey;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}
.call-btn i {
  font-size: 2rem;
}
.call-hover-effect:hover {
  cursor: pointer;
  background-color: rgb(22 20 102);
  color: white;
  .call-hover-effect {
    color: white;
  }
}
.wtsp-btn i {
  font-size: 2.5rem;
}
.wtsp-btn {
  z-index: 9999;
  height: 58px;
  width: 58px;
  position: fixed;
  left: 21px;
  bottom: 20px;
  text-align: center;
  background-color: white;
  border-radius: 10px;
  color: #25d366;
  cursor: pointer;
  font-size: 1.2rem;
  box-shadow: 1px 1px 10px 1px grey;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.wp-hover-effect:hover {
  cursor: pointer;
  background-color: #25d366;
  .wp-hover-effect {
    color: white;
  }
}
