.section-heading {
  color: #16427f;
  font-size: 3rem;
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

.section-inner-heading {
  color: black;
  font-size: 2rem;
  font-family: "Playfair Display", serif;
  font-weight: 500;
}
Link{
  text-decoration: none;
}
.bg-section-color {
  background-color: #f7f6f6db;
}
.home-container {
  /* margin-top: -10vh; */
  font-family: "Playfair Display", serif;
}

.slide-btn {
  display: inline-block;
  background: white;
  padding: 17px 50px;
  color: #16427f;
  border: 1px solid #16427f;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  outline: 0;
  box-shadow: none;
  transition: 0.5s;
}
.slide-btn-updated {
  display: inline-block;
  padding: 17px 50px;
  border: 1px solid #16427f;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  outline: 0;
  box-shadow: none;
  transition: 0.5s;
  background-color: #16427f;
  color: #f5f5fb;
  border-radius: 10px;
}

.slide-btn-updated:hover {
  color: #16427f;
  background: transparent;
  border: 1px solid #16427f;
}
.slide-btn:hover {
  background: #16427f;
  color: #fff;
  border: 1px solid #fff;
}
.at {
  padding: 14px;
  color: black;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  line-height: 1.5;
  text-align: justify;
  /* margin-bottom: 30px; */
}

/*********************************/
.home-slider {
  width: 100%;
  height: 80vh;
}

/*......counters......*/
.home-counters {
  background-color: #fffafa;
  width: 100%;
  /* height: 125vh; */
}

/*......About us.......*/

.about-main-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}

.about-img-1 {
  width: 40vw;
  height: 55vh;
  margin-left: 25%;
  border-radius: 10px;
}

.about-img-2 {
  width: 30vw;
  height: 55vh;
  margin-top: -250px;
  border-radius: 10px;
}

.about-text-containermain {
  margin-left: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-text-heding {
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  color: #888;
}

.about-text-paregraph {
  font-size: 22px;
  width: 90%;
  text-align: justify;
}

.about-butn {
  width: 25%;
  height: 6%;
  background-color: #16427f;
  font-size: 1.1rem;
  margin-top: 40px;
}

/*....Services....*/

.services-text-main {
  padding-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.services-text-heding {
  font-family: "Poppins", sans-serif;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #36313d;
}

.services-text-paregraph {
  font-size: 24px;
  /* width: 70%; */
  /* margin-left: 15%; */
  color: #888;
  margin: 10px;
}

.service-cards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10%;
  row-gap: 5vh;
  padding: 3%;
}

/*...why to chooses...*/
.chooses-text-heding-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.chooses-video-main {
  width: calc(var(--window-width) * 0.8);
  height: auto;
  margin: auto;
  max-width: 912px;
  /* max-height: 512px; */
}

.chooses-video {
  width: 100%;
  max-width: 912px;
  max-height: 512px;
  height: calc(var(--window-width) * 3 / 4 * 0.6);
  border-radius: 15px;
}

.chooses-text-main {
  /* background-color: rgb(238, 238, 238); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chooses-text-p {
  color: black;
  font-size: 20px;
  font-family: "Poppins", sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  /* line-height: 1.5; */
  text-align: justify;
  margin: 10px;
  font-weight: bold;
}

.chooses-text-snp {
  /* text-align: justify; */
  /* color: #888; */
}

.chooses-text-paregraph {
  /* text-align: center; */
  font-size: 24px;
  color: #888;
  /* width: 70%; */
  /* margin-left: 13%; */
}

/*...feature...*/

.feature-text-heding-main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature-box-main-f1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.feature-box-main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10%;
  padding: 5%;
  margin-top: -20px;
}

.feature-box {
  width: 330px;
  height: 263px;
  text-align: center;
  /* background-color: antiquewhite; */
}

.feature-logo {
  width: 24%;
  height: 11vh;
  margin-bottom: 17px;
}

.feature-heding-text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

/*...testimonials */

.testimonials-text-heding-main {
  text-align: center;
  margin: 10px;
}
.testimonials-box-main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 5%;
  padding: 2%;
}

.testimonials-box {
  margin: 2px;
  width: 300px;
  height: 300px;
  text-align: center;
  background-color: rgb(231 241 255);
  padding: 50px 0px;
}

.testimonials-logo {
  width: 40%;
  border-radius: 20px;
  margin-top: 15px;
}

.testimonials-heding-text {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.testimonials-heding-textp {
  width: 85%;
  margin-left: 20px;
  text-align: justify;
  color: #888;
}

@media (max-width: 1200px) {
  /* .home-counters,
    .home-img {
        height: 88vh;
    } */
  .about-text-containermain {
    margin-left: 0;
  }
  .about-text-main {
    font-size: 22px;
    width: 22vw;
    padding: 0.3%;
    margin: 0 auto;
  }
  .about-text-heding {
    text-align: center;
    padding-top: 20px;
  }

  .about-img-fluid {
    display: none;
  }

  .services-text-heding,
  .about-text-heding {
    font-size: 2rem;
  }

  /* .about-text-heding{
        padding-right: 15%;
    } */

  .services-text-paregraph,
  .about-text-paregraph {
    font-size: 1.3rem;
  }

  .about-butn {
    margin: 20 auto;
  }

  .services-text-heding-main {
    /* width: 28vw; */
    font-size: 26px;
    padding: 0.5%;
  }

  .chooses-text-paregraph {
    /* margin-top: 40px; */
  }

  .chooses-text-heding-main {
    font-size: 26px;
    /* width: 36vw; */
    padding: 0.5%;
  }

  .testimonials-text-heding-main,
  .feature-text-heding-main {
    font-size: 26px;
    /* width: 26vw; */
    padding: 0.5%;
  }

  .testimonials-box-main {
    row-gap: 20px;
  }
}

@media (max-width: 768px) {
  /* .home-slider img,
    .home-slider {
        height: 35vh;
    } */
  .about-text-main {
    font-size: 24px;
    width: 35vw;
    /* height: 5%; */
    padding: 0.1%;
  }
  .services-text-paregraph {
    text-align: center;
  }
  .services-text-heding-main {
    /* width: 30vw; */
    font-size: 24px;
    padding: 0.5%;
  }
  .chooses-text-heding-main {
    font-size: 24px;
    /* width: 46vw; */
    padding: 0.5%;
  }
  .testimonials-text-heding-main,
  .feature-text-heding-main {
    font-size: 24px;
    /* width: 36vw; */
    padding: 0.5%;
  }
}

@media screen and (max-width: 600px) {
  .home-counters,
  .home-img {
    /* height: 185vh; */
  }
  .section-heading{
    font-size: 2.1rem;
  }

  .home-slider{
    height: 50vh;
  }

  .about-text-main {
    font-size: 22px;
    /* width: 45vw; */
    /* height: 4%; */
    padding: 0.3%;
  }

  .about-butn {
    /* width: 200px; */
  }
  .services-text-heding-main {
    /* width: 50vw; */
    font-size: 22px;
    padding: 0.5%;
  }
  .chooses-text-heding-main {
    font-size: 22px;
    /* width: 66vw; */
    padding: 0.5%;
  }
  .testimonials-text-heding-main,
  .feature-text-heding-main {
    font-size: 22px;
    /* width: 56vw; */
    padding: 0.5%;
  }
  .services-text-heding,
  .about-text-heding {
    font-size: 1.5rem;
  }
  /* .testimonials-box-main {
     margin-left: 10%;
    } */
  .chooses-text-paregraph {
    /* margin-top: 20%; */
    font-size: 1rem;
  }
  .services-text-paregraph,
  .about-text-paregraph {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 467px) {
  .home-counters,
  .home-img {
    /* height: 200vh; */
  }
  .chooses-text-p span{
    font-size: 14px;
  }
}

@media screen and (max-width: 386px) {
  .home-counters,
  .home-img {
    /* height: 175vh; */
  }
  .about-text-heding {
    padding-right: 4%;
    font-size: 22px;
  }
  .services-text-paregraph,
  .about-text-paregraph {
    font-size: 1rem;
  }
}
