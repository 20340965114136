.contact-div{
    height: 100%;
    width: 100%;
}

.con-img-fluid {
    width: 100vw;
    height: 80vh;
    margin-top: -10%;
}

.con-banner-img {
    width: 100%;
    height: 80vh;

}
/* .contactMain-m3{
    margin-left: 1%;
    margin-top: 50px;
} */

.contact-container2{
    /* height: 77vh; */
    padding-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.contact-heading{
    padding-top: 2%;
}
.con-text-heding-main1{
    /* background-color: rgb(54, 135, 233); */
    /* color: #fffafa; */
    /* width: 29%; */
    /* height: 7%; */
    /* border-radius: 15px; */
    /* font-size: 1.8rem; */
    /* margin: 0 auto; */
    text-align: center;
    padding: 5px;
    /* padding-left: 3%; */
}
.con-all-heading{
    text-align: center;
    /* font-size: 2.1rem; */
    font-weight: 700;
    padding-bottom: 3%;
    padding-top: 3%;
}
.con-all-subheading{
  font-family: "Playfair Display", serif;
    text-align: center;
    padding-bottom: 4%;
    font-size: 1.5rem;
    font-weight: 400;
    color: #16427f;
    padding-right: 10%;
    padding-left: 10%;
    padding-top: 5%;
}


.con-social-logo {
    width: 37px;
    height: 37px;
  
  }
  
  .footer-menu-socialLink {
    margin: 35px;
  }
  .con-logo1 {
    width: 30px;
    height: 30px;
    padding: 4px;
    border-radius: 5px;
    color: #ffffff;
    background-color: #16427f;
  }
  
  
  .con-logo {
    display: flex;
    justify-content: center;
  }
  
  .con-link-headings {
    font-weight: bold;
    font-size: 2rem;
    padding-bottom: 20px;
    text-decoration: none;
    color: rgb(10, 10, 10);
  }
  
.con-icon3 {
    color: #888;
    font-size: 20px;
    margin-right: 5px;
    margin-top: 10px;
  }
  
  .con-icons-1 {
    margin-top: 20px;
  }
  
  
  .con-social-headings {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 4px;
    display: flex;
    text-decoration: none;
    padding-bottom: 10px;
    padding-top: 7%;
    justify-content: center;
    color: #16427f;
  
  }
  
  .con-contactUs-headings {
    font-weight: bold;
    font-size: 2rem;
    text-decoration: none;
    padding-bottom: 20px;
    color: rgb(26, 18, 18);
  }
  
  .con-link-list {
    color: rgb(21, 19, 19);
  }
  .con-adde-main{
    display: flex;
    flex-direction: row;
    padding-top: 6%;
  }
  
  .con-contactUs-link-list {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: rgb(16, 16, 16);
  }

  
  .con-social-link-list {
    text-align: center;
    padding-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .con-social-link-list li {
    display: inline;
    justify-content: space-around;
    margin: 10px;
  }
/* contact-form */
  /* .con-form-img{
    height: 500px;
    width: 450px;
  } */


  .con-form form{
    width: 100%;
    margin:0 auto;
    position: relative;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: rgba(255, 255, 255, 0.3);
   
  }
  .con-form form:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
     background-image: url('../../images//Slider4.jpeg');
    background-size: cover;
    background-position: center;
     z-index: -1;
    border-radius: 8px;
    opacity: 0.4;
  }
  
  .con-form-label {
    display: block;
    margin: 6px 20px;
    
  }
  
  
  .con-form textarea {
    display: block;
    margin: 10px 0;
    width: 100%;
    border-radius: 7px
  }
  .con-form input{
    background-color: #fff;
    border-radius: 0%;
    border: 1px solid black !important;
    width: 100%;
    padding: 8px;
    box-Sizing: border-box;
    margin-Top: 4px;
    border-radius: 7px
  }
  .contact-btn{
   
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .con-form button{
    background-color: #154486;
    color: white;
    width: 13vh;
    padding: 10px 15px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
  }
  





.box-map{
    padding-top: 45px;
    padding-bottom: 10px;
}
.google-m-2{
    width: 100vw;
    height: 600px;
}

@media  (max-width:768px) {
    .con-text-heding-main1{
        /* width: 30%; */
        /* font-size: 1.1rem; */
        /* padding-left: 2%; */
    }

    .contact-container2{
        height: 100%;
        flex-direction: column;
    }
    .con-all-subheading{
        padding: 0;
    }
    .con-adde-main{
        padding: 0;
        margin-left: 27%;
        align-content: center;
    }
    .con-form form {
        width: 75%;
        margin: 0 auto;
        background: rgba(255, 255, 255, 0.8);
        display: flex;
        flex-direction: column;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
    .con-social-link-list {
        text-align: center;
        padding-top: 9px;
        padding-bottom: 18px;
       
    }
   
      
    
}

@media (max-width: 412px){

    .con-text-heding-main1 {
        /* width: 37%; */
        /* font-size: 0.9rem; */
        /* padding-left: 2%; */
    }
    .con-form form{
        width: 68%;
    }
    .con-adde-main {
        margin-left: 14%;
    }
    .contact-container2 li {
        font-size: 0.9rem;
     
    }
    .con-logo1{
        height: 20px;
        width: 20px;
    }
    .con-form-label {
        display: block;
        margin: 0px 0;
    }
}