.eligibility-calculator {
  max-width: 80%;
  height: 100%;
  margin: 0 auto;
  padding: 4%;
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 10%; */
}
.eligibility-main {
  width: 100vw;
}
.eligible-banner-img img {
  width: 100%;
  margin-top: -7vh;
  height: 50vh;
}
.eligi-img-fluid {
  background: url(../../images/pics/eligibilityloan.jpeg);
  background-position: center;
  background-size: cover;
}
.input-container {
  margin-bottom: 3%;
  /* width: 25vw; */
  border: none !important;
  background-color: #fff;
  border-radius: 0%;
  border-bottom: 1px solid black !important;
}

.listcontainer {
  margin-bottom: 3%;
  /* width: 25vw; */
  border: none !important;
  background-color: #fff;
  border-radius: 0%;
}
.form-container {
  width: 60%;
}

.result-group p {
  font-size: 2.2em; /* Increase the font size of the result text */
}
.result-group h1 {
  font-size: 25px;
}

.input-container label {
  display: block;
  /* font-size: 1.3rem; */
}

.input-container input {
  border: none;
  /* border-bottom: 2px solid #333; Adjust the color as needed */
  outline: none; /* Remove the default input outline */
  width: 100%;
  padding: 6px;
  margin: 5px 0;
  box-sizing: border-box;
}

/* Add a focus style for better user experience */
.input-container input:focus {
  /* border-bottom: 2px solid #007BFF; Adjust the color as needed */
}
.input-container input:focus,
.input-container input:valid {
  outline: none;
  border-color: #007bff; /* Change the border color when focused or has valid input */
  font-size: 1.5em; /* Increase the font size when focused or has valid input */
}

.image-container {
  flex: 1;
  max-width: 30%;
  margin-right: 10%;
}

.image-eli {
  width: 100%;
  height: 50vh;
  margin-top: 22%;
  box-shadow: 5px 4px 5px #979797;
}
.employetype{
  display:'flex';
  justify-content:'space-between';
  align-content:'center';
}
@media screen and (max-width: 1200px) {
  .button-gp button {
    min-width: 16vw;
    padding: 1vw;
  }
  .form-container {
    width: 70%;
  }
  .image-eli {
    padding-top: 20%;
    width: 40vw;
    height: 45vh;

    box-shadow: 5px 4px 5px #979797;
  }
  .image-container {
    flex: 1;
    padding-top: 10%;
    width: 50%;
    padding-right: 10%;
  }
}

@media screen and (max-width: 768px) {
  .form-container {
    width: 80%;
    flex-direction: column;
    align-items: center;
  }
  .form-container h2 {
    font-size: 1.5em;
    text-align: left;
  }
  .button-gp button {
    min-width: 28vw;
    padding: 1vw;
  }
  .input-container {
    width: 60vw;
  }
}

@media screen and (max-width: 650px) {
  .image-eli {
    display: none;
  }
}
@media screen and (max-width: 582px) {
  .employetype{
    display:'flex';
    flex-direction: row;
  }
}