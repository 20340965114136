*{
    margin: 0;
    font-family: 'Lato', sans-serif;
}
.parent-flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 20px;
}
.service-parent-flex-center{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.font-size{
    font-size: 19px;
    font-weight: 300;
}