/* Navbar style */
.navbar {
  background-color: white;
  width: 93vw;
  position: fixed;
  font-size: 1.2rem;
  height: 80px;
  display: flex;
  justify-content: space-between;
  padding: 0px 6vw 0px 5vw;
  align-items: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index:999;
  box-shadow: 0px 4px 8px rgba(4, 48, 73, 0.4);
}

/* List of all pages link style */
.navbar-menu {
  list-style: none;
  border-radius: 10px;
  padding: 0;
  margin: 0;
}

.navbar-menu li {
  display: inline-block;
  font-size: 16px;
}

.navbar-menu li , .linkn1 {
  text-decoration: none;
  color: black;
  background: transparent;
  /* font-weight: 700; */
  font-family: "Lato", sans-serif;
  /* margin-top: 25px; */
  /* padding-bottom: 25px; */
  /* padding-left: 12px; */
}

.navbar-menu li.active  .linkn1{
  background: transparent;
  color: #16427F;
  /* font-weight: 1000; */
  font-family: "Lato", sans-serif;
  /* margin-top: 11px; */
  /* padding-bottom: 25px; */
  /* padding-left: 27px; */
}

/* Dropdown style */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  /* border-radius: 2px; */
  z-index: 1;
  padding: 0px;
  width: 184px;
  /* border: 1px solid black; */
  /* margin-top: 5px; */
}

/* Style for dropdown links */
.dropdown-content  .linkn1{
  color: black;
  padding: 12px 18px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

/* Change color on hover */
.dropdown-content  .linkn1:hover {
  background-color: #f1f1f1;
}
.close-button {
  display: none;
}
.linkn1{
  color: #101010;
}
/* Show dropdown on hover */
.navbar-menu li:hover .dropdown-content {
  display: block;
}

.head-img-1 {
  width: 65px;
  /* margin-top: 10px; */
  height: 65px;
}

.logo {
  font-size: 1.5rem;
  padding: 5px;
}

.nav-links {
  display: flex;
  flex-direction: row;
  gap: 3rem;
}

.nav-links  .linkn1 {
  text-decoration: none;
  color: #111010;
  font-size: 1.2rem;
  transition: color 0.3s ease-in-out;
}

.nav-links  .linkn1:hover {
  background: transparent;
  color: #16427F;
  /* font-weight: 1000; */
}

/* Show dropdown on hover */
.navbar-menu li:hover .dropdown-content {
  display: block;
}

.hamburger-icon {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.Down-main {
  display: flex;
    justify-content: center;
    align-items: center;
}


/******************/
#menu_checkbox {
  display: none;
}

.label {
  position: absolute;

  margin: 0 auto;
  display: block;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  transition: 0.3s ease transform;
  cursor: pointer;
}

.label div {
  width: 6px;
  height: 6px;
  background-color: #16427F;
  margin-left: 0;
  margin-bottom: 6px;
  border-radius: 4px;
  transition: 0.3s ease width, 0.3s ease margin-left, 0.3s ease margin-bottom,
    0.3s ease background-color;
}

.label div:first-child {
  width: 22px;
}

.label div:nth-child(2) {
  width: 15px;
}

.label div:last-child {
  width: 30px;
  margin-bottom: 0;
}

#menu_checkbox:checked + .label {
  transform: translateY(-50%) rotateZ(-90deg);
}

#menu_checkbox:checked + .label div {
  width: 6px;
  margin-left: 12px;
  margin-bottom: 3px;
  background-color: #16427F;
}
#menu_checkbox:checked {
}
/***********************/
@media screen and (max-width: 1045px) {
  .nav-links {
    /* display: none; */
    position: fixed;
    /* Fix the position */
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    text-align: center;
    z-index: 5;
    transform: translateX(-100%);
    /* Initially slide the menu off-screen */
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    padding: 10px;
    transform: translateX(0);
    /* Slide in the menu when open class is present */
    /* height: 380px; */
    margin-top: 80px;
    margin-left: -15px;
    z-index: 4;
  }
  .navbar-menu li{
    display: block;
    width: 92vw;
    text-align: left;
    font-size: 16px;
  }

  .navbar-menu li  .linkn1 {
    display: block;
    width: 92vw;
    margin-top: 0;
    padding-bottom: 0;
  }
.dropdown-content li  .linkn1{
  border-bottom: none;
}
  .navbar-menu li.active  .linkn1 {
    margin-top: 0px;
    padding-bottom: 0px;
    padding-left: 11px;
  }
  .Down-arrow{
    margin-top: 9px;
    height: 28px;
    width: 34px;
  }
  .hamburger-icon {
    display: block;
    margin-right: 10%;
  }

  .close-button {
    display: block;
    cursor: pointer;
    padding: 10px;
    color: #111010;
    margin-left: 70%;
    margin-top: -30px;
    font-size: 1.8rem;
    transition: color 0.3s ease-in-out;
  }

  .close-button:hover {
    color: #5e605f;
  }

  .navbar-menu {
    align-items: flex-start;
    list-style: none;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    color: #0a0a0a;
    padding: 0;
    /* height: 26px; */
    margin: 0;
  }

  .navbar-menu li  .linkn1{
    color: #0a0a0a;
    font-size: 1.5rem !important;
  }

  .dropdown-content {
    width: 100%;
    margin-top: 12px;
    position: relative;
    z-index: 2;
  }

  .dropdown-content {
    width: 90vw;
    margin-top: 14px;
    margin-bottom: 10px;
    border: none;
    border-radius: 10px;
    padding-bottom: 10px;
  }

  .Down-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* margin-left: 10%; */
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .navbar-menu li  .linkn1{
    color: #0a0a0a;
    font-size: 1.3rem !important;
    font-weight: 400;
  }
}
