.real-estate-info-container {
    height: 70vh;
    width: 100%;
    background-color: rgba(241, 241, 247, 0.85);
  }
  
  .real-slider{
    background-color: white;
    width: 100vw;
    height: 70vh;
    /* margin-top: -10vh; */
   
}
.real-slider img{
    width: 109%;
    height: 70vh;
    margin-left: -7%;  
    margin-bottom: 1%;
}
.slider-data{
  font-size: 5rem;
  color: white;
}
  
  .real-header {
    /* width: 230px; */
    /* background-color: rgb(54, 135, 233); */
    text-align: center;
    /* margin-left: 42%; */
    /* display: flex; */
    /* border-radius: 1vh; */
    /* justify-content: center; */
    
  }
  
  .real-header-text {
    /* font-size: 1.1rem; */
    /* padding-top: 2%; */
    /* padding-bottom: 2%; */
    /* color: white; */
  }
  
  .real-left,
  .real-bottom1 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 2px;
    padding-right: 4%;
    padding-left: 4%;
  }
  .real-left2,
  .real-bottom2 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin: 2px;
    padding-right: 4%;
    padding-left: 4%;
  }
  .home-cal-logo{
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  
  .home-cal-logo img,
  .home-cal-logo1 img {
    height: 200px;
    width: 200px;
    /* padding-left: 23%; */
    /* padding-bottom: 3vh; */
    align-items: center;
  }
  .home-cal-logo p,
  .home-cal-logo1 p{
    font-size: 20px;
    font-weight: 500;
    font-style: bold;
  }
  
  .home-cal-text,
  .home-cal-text1 {
    /* height: auto; */
    width: 80%;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .text-home-cal,
  .text-home-cal1 {
    width: 100%;
    /* line-height: 1.5; */
    /* margin-top: 5%; */
    /* margin-bottom: 5%; */
    /* color: #888; */
  }
  
  .real-pb-video iframe,
  .real-pb-video-fluid iframe,
  .real-bb-video iframe,
  .real-bb-video-fluid iframe{
    width: 90%;
    height: 65vh;
    margin-left: 5.5%;
    border-radius: 10px;
    margin-top: 8%;
    margin-bottom: 4%;
  }
  
  .real-bb-video,
  .real-pb-video-fluid {
    margin-bottom: 5vh;
    object-fit: fill;
  }
/*   
  @media only screen and (max-width: 830px) {
    .real-estate-info-container {
      height: 87vh;
    }
  }
  
  @media  screen and (max-width: 735px) {
    .real-estate-info-container {
      height: 50vh;
      background-color: white;
    }
    .real-slider{
      height: 50vh;
    } 
    .real-slider img {
      height: 50vh;
      border-radius: 0;
    }
  
    .real-header {
      height: 2vh;
      padding: 2%;
      margin-top: 1vh;
      border-radius: 1vh;
      margin-left: 25%;
      margin-bottom: 4vh;
      align-items: center;
    }
    .text-home-cal, .text-home-cal1 {
      text-align: center;
    }
  
    .real-header-text {
      font-size: 2.1vw;
    }
  
    .real-left,
    .real-bottom1 {
      height: auto;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: -9vh;
    }
   
    .real-bottom2{
        height: auto;
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 15%;
        margin-bottom: -9vh;
      }
   
  
    .home-cal-logo img,
    .home-cal-logo1 img {
      height: 8vh;
      width: 8vh;
    }
  
    .home-cal-text,
    .home-cal-text1 {
      height: auto;
    }
  
    .real-pb-video iframe,
    .real-pb-video-fluid iframe,
    .real-bb-video iframe,
    .real-bb-video-fluid iframe {
      height: 25vh;
      margin-top: 8vh;
      margin-bottom: 6vh;
      width: 93%;
    
    }
  }
  @media screen and (max-width:530px){
    .real-header {
      width: 130px;
  }
  .real-header-text{
    font-size: 10px;
  }
    
  } */