.container{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.loan-calculator-container {
    max-width: 80%;
    height: 100%;
    margin: 0 auto;
    padding: 4%;
    border: 1px solid #ccc;
    border-radius: 2%;
  }
  .loan-calc-main{
    width: 100vw;
  }
  .loan-banner-img img{
    width: 100%;
    /* margin-top: -7vh; */
    height: 70vh;
  }
  .homelcal-img-fluid{
    background: url(../../images/Home-Loan-Calculator.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .input-group {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    margin-bottom: 10%;
    /* width: 25vw; */
    border: none !important;
    background-color: #fff;
    border-radius: 0%;
    border-bottom: 1px solid black !important;
  }
  
  .input-group label {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 2%;
  }
  .input-group input{
    border: none;
    font-size: 2rem;
  }
  
  .con-in {
    width: 94%;
  padding: 2%;
  border: none !important;
  border-radius: 2%;
  transition: border-color 0.3s;
  font-size: 1.2em; 
  }
  .con-in:focus,
  .con-in:valid {
  outline: none;
  border-color: #007bff;
  font-size: 1.5em; 
}
  .button-group {
    display: flex;
    gap: 2%;
    margin-top: 9%;
  }

  .result-group {
    margin-top: 4%;
    background-color: #16427f;
    color: white;
    text-align: center;
    border-radius: 10px;
    padding: 1%;
    margin-bottom: 10%;

  }
 
  
  .result-group p {
    font-size: 2.2em; /* Increase the font size of the result text */
  }
  
  .calculator-content h1 {
    font-size: 3.1rem;
    color: brown;
    font-weight: 500;
    margin-bottom: 10%;
}
  
  .calculator-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5%;
  }
  
  .calculator-content {
    /* flex: 1; */
    /* max-width: 31%; */
    width: 60%;
    padding: 4%;
  }
  
  .calculator-image {
    flex: 1;
    max-width: 35%;
    margin-right: 10%;
  }
  
  .calculator-image-1 {
    width: 100%;
    height: 50vh;
    box-shadow: 5px 4px 5px #979797;
  }


  @media screen and (max-width: 1200px) {

     
  .calculator-image {
    flex: 1;
    width: 30vw;
    padding-right: 10%;
   
  }
  
  .calculator-image-1 {
    width: 50vw;
    height: 50vh;
    box-shadow: 5px 4px 5px #979797;
  }
  .button-group button {
    min-width: 13vw; 
    padding: 1vw; 
   
  }
  }


  @media screen and (max-width: 768px) {
   .calculator-content h1 {
        font-size: 2.1em;
        text-align: left;
        
    }
    .calculator-container{
        width: 100%;
        flex-direction: column; 
      align-items: center;
       
    }
    .calculator-image {
      display: none;
    }
  
    .calculator-content {
      
      max-width: 63%;
      /* height: 40vh; */
    
    }
    .input-group {
        width: 50vw;
    }
    .input-group label{
        font-size: 1.1rem;
    }
    .button-group button {
        min-width: 27vw; 
        padding: 1vw; 
        margin-bottom: 10%;
      }

     
    
  }