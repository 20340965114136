/* Add your existing styles here */

body {
  font-family: "Playfair Display", serif;
}
.row {
  /* margin: 0 -5px; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 5%;
  font-family: "Times New Roman", Times, serif;
}

.flexy-box {
  flex: 1;
  min-width: 200px; /* Adjust this value based on your design */
  margin: 8px;
}

.text-n1 {
  font-size: 24px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  padding: 25px;
  text-align: center;
  color: rgb(12, 12, 12);
}

.fa {
  font-size: 80px;
}

/* Add animation for the counter increment */
@keyframes countUp {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

h3 {
  font-size: 45px;
  animation: countUp 0.2s ease-in-out;
}
@media screen and (max-width: 600px) {
  h3 {
    font-size: 32px;
    animation: countUp 0.2s ease-in-out;
  }
}