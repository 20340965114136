.home-section {
    height: 120vh;
    padding: 5%;
}

.insurance-banner-img2 img {
    width: 100%;
    margin-top: -10vh;
    height: 80vh;
    margin-bottom: 20px;
}

.insurance-section-row {
    display: flex;
    column-gap: 15%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transition: 0.5s;
}

.list-item1,
.list-item2,
.list-item3 {
    background-color: #16427f;
    width: 200px;
    border-radius: 15px;
    letter-spacing: 2px;
    font-size: 2rem;
    color: #fff;
    padding: 1.5%;
    text-align: center;
    transition: 0.5s;
}

.list-items1,
.list-items2,
.list-items3 {
    background-color: rgb(152, 154, 155);
    width: 200px;
    height: 3px;
    padding: 0.5%;

}


.list-items3.active {
    background-color:#16427f;
}




.heading-font-size {
    font-size: 2rem;
    letter-spacing: 1px;
    margin-bottom: 25px;
}

.insurance-social-impact-content {
    font-size: 1.4rem;
}



/* .insurance-section-img .insurance-img1 {
    width: 700px;
    height: 507.5px;
    text-align: center;
} */
.insurance-section-content{
    background-color: rgb(231 241 255);
    width: 80vw;
    height: fit-content;
    padding: 2%;
    text-align: center;
    border-radius: 10px;
}

.insurance-section-container-list2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;

}


.insurance-section-box {
    background-color: rgb(198, 195, 195);
    width: 80vw;

}

.insurance-section-image,
.insurance-health-image {
    width: 80vw;
    padding-left: 5%;
    margin-bottom: 30px;
}

.insurance-section-video {
    padding-left: 10%;
}

.insurance-health-video {
    width: 80vw;
    height: 600px;
    margin-bottom: 30px;
}

.insurance-box-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.insurance-box-main {
    width: 700px;
    height: 810px;
    /* background-color: rgb(223, 223, 223); */
    margin-bottom: 20px;
    position: relative;
    padding: 2%;
}
.insurance-box-main:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0; 
    width: 100%;
    height: 100%;
     background-image: url('../../images//Slider4.jpeg');
    background-size: cover;
    background-position: center;
     z-index: -1;
    border-radius: 8px;
    opacity: 0.4;
  }

.in-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.insurance-text-main {
    width: 220px;
    height: 50px;
    /* background-color:rgb(231 241 255); */
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 20px;
   


}

.insurance-text {
    font-size: 1.5rem;
    font-weight: bold;
    color: #16427f;
    text-align: center;
    padding: 10px;
    border-radius: 2px;


}

.insurance-form-text {
    width: 500px;
    height: 60px;
    font-size: 1.5rem;
    padding: 2%;
    margin-bottom: 20px;

}

.insurance-form-text1 {
    width: 470px;
    height: 35px;
    padding: 2%;
    font-size: 1.5rem;
    border: 1px solid #646464 !important;
    margin-bottom: 20px;
}

.insurance-input-text2 {
    display: flex;
    column-gap: 3.1%;
    justify-content: center;
    align-items: center;
}

.insurance-form-text2 {
    width: 220px;
    height: 35px;
    padding: 2%;
    font-size: 1.5rem;
    border: 1px solid #646464 !important;
    margin-bottom: 20px;
}

.insurance-section-row li:hover {
    cursor: pointer;
    background-color: rgb(231 241 255);
    color: #000;
}

.Insurance-btn{
   
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .Insurance-submit{
    /* background-color: #1a70e7; */
    color: white;
    width: 130px;
    padding: 10px 15px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.2rem;
  }

 

 


@media only screen and (max-width: 1030px) {
    /* .insurance-section-container-list2 {
        flex-direction:column-reverse;
    } */

    /* .insurance-section-img .insurance-img1 {
        width: 53vw;
        height: 400px;
       
    } */
    .insurance-section-row{
        column-gap: 5%;
    }
    .insurance-section-content{
        height:fit-content;
    }

    .insurance-health-video {
        height: 330px;
       
    }

    

}

@media only screen and (max-width: 730px) {
    .insurance-section-row {
        padding: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
    }

    .list-item1,
    .list-item2,
    .list-item3 {
        width: 110px;
        padding: 0;
        font-size: 1.5rem;
        letter-spacing: normal;
        color: white;
    }

    .list-items1,
    .list-items2,
    .list-items3 {
        width: 110px;
        padding: 0;
        font-size: 1.5rem;
        letter-spacing: normal;
        color: white;
    }

   
    
    .heading-font-size {
        font-size: 1.8rem;  
    }
    
    .insurance-social-impact-content {
        font-size: 1.2rem;
    }
    
    .insurance-section-img .insurance-img1 {
        width: 53vw;
        height: 401px;
    }
    /* .insurance-section-content{
        width: 45vw;
      
        
    } */
/*     
    .insurance-section-container-list2 {
        margin-top: 40px;
        margin-bottom: 40px;
    } */

    .insurance-box-main {
        width: 500px;
        height: fit-content;
      
    }
    .insurance-text-main {
        width: 180px;
        height: 50px;
    
    }
    
    .insurance-text {
        font-size: 1.2rem;
    }
    .insurance-form-text {
        width: 400px;
        height: 50px;
        font-size: 1.3rem;
    }
    
    .insurance-form-text1 {
        width: 370px;
        height: 25px;
        font-size: 1.3rem;
    }
    .insurance-form-text2 {
        width: 170px;
        height: 25px;
        font-size: 1.3rem;
    }

}

@media only screen and (max-width: 555px) {
    .heading-font-size {
        font-size: 2rem !important;
        line-height: 50px;
    }

    /* .insurance-social-impact-content-box {
        width: 310px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    } */
    
    .heading-font-size {
        font-size: 1.5rem;  
    }
    
    .insurance-social-impact-content {
        font-size: 1rem;
    }
    
    .insurance-section-img .insurance-img1 {
        width: 371px;
        height: 385px;
    }

    /* .insurance-section-img .insurance-img1 {
        width: 72.5vw;
    height: 350px;
    }
    .insurance-section-content{
        width: 65vw;  
    } */
    .insurance-health-video {
        height: 280px;
       
    }

    .insurance-box-main {
        width: 400px;
        height: fit-content;
      
    }
    .insurance-form-text {
        width: 400px;
        height: 50px;
        font-size: 1.3rem;
    }
    
    .insurance-form-text1 {
        width: 370px;
        height: 25px;
        font-size: 1.3rem;
    }
    .insurance-form-text2 {
        width: 170px;
        height: 25px;
        font-size: 1.3rem;
    }
    
}

@media only screen and (max-width: 445px){
    /* .insurance-section-row{
        background-color:  rgb(69, 136, 204);
    
    } */

    .list-item1,
    .list-item2,
    .list-item3 {
        width: 80px;
        padding: 0;
        font-size: 1rem;
        letter-spacing: normal;
        color: white;
    }
    .list-items1,
    .list-items2,
    .list-items3 {
        width: 80px;
        padding: 0;
        font-size: 1rem;
        letter-spacing: normal;
        color: white;
    }

    /* .insurance-social-impact-content-box {
        width: 260px;
        height: fit-content;
        display: flex;
        
        
    } */
    
    .heading-font-size {
        font-size: 1.2rem;  
    }
    
    .insurance-social-impact-content {
        font-size: 0.8rem;
    }
    
    .insurance-section-img .insurance-img1 {
        width: 310px; 
    height: 250px;
    }
    /* .insurance-section-content{
        width: 280px;  
    } */
    .insurance-health-video {
        height: 230px;  
    }

    .insurance-box-main {
        width: 280px;
        height: fit-content;
      
    }
    .insurance-form-text {
        width: 200px;
        height: 37px;
        font-size: 12px;
    }
    
    .insurance-form-text1 {
        width: 175px;
        height: 25px;
        font-size: 12px;
    }
    .insurance-form-text2 {
        width: 82px;
        height: 25px;
        font-size: 12px;
      
    }
    .insurance-text-main {
        width: 140px;
        height: 30px;
    
    }
    .insurance-text {
        font-size: 0.8rem;
    }
    .Insurance-submit{
     
        width: 100px;
      
       
      }
}
/*----------*/











