.loan-img-fluid {
  background: url(../../images/Personal-loan.jpg);
  position: relative;
  width: 100%;
  height: 450px;
  background-position: center;
  background-size: cover;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loan-img-fluid h2 {
  color: white;
  text-decoration: underline;
}
.personal-img-fluid {
  background: url(../../images/Personal-loan.jpg);
  background-position: center;
  background-size: cover;
}
.business-img-fluid {
  background: url(../../images/Business.jpg);
  background-position: center;
  background-size: cover;
}
.agri-img-fluid {
  background: url(../../images/agri.jpg);
  background-position: center;
  background-size: cover;
}
.vehical-img-fluid {
  background: url(../../images/vhical.jpg);
  background-position: center;
  background-size: cover;
}
.edu-img-fluid {
  background: url(../../images/education.webp);
  background-position: center;
  background-size: cover;
}
.container {
  padding-bottom: 40px;
}
.loan-banner-img1 img {
  width: 100%;
  margin-top: -10vh;
  height: 80vh;
}

.heder {
  width: 20vw;
  height: 40px;
  background-color: rgb(54, 135, 233);
  margin-top: 20px;
  text-align: center;
  margin-left: 40%;
  border-radius: 15px;
  margin-bottom: 40px;
}

.heder-text {
  font-size: 2rem;
  color: white;
}

.video,
.video-fluid {
  width: 90vw;
  height: 500px;
  margin-left: 2.5%;
}

.text {
  margin-top: 20px;
  font-size: 1.5rem;
  /* width: 80%; */
  /* text-align: center; */
  /* margin-left: 10%; */
  max-width: 1200px;
}

.image-main {
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
  width: 90vw;
}

.images {
  width: 40%;
  /* margin-left: 10%; */
}
@media (max-width: 1200px) {
  .heder {
    width: 35%;
    margin-left: 30%;
  }
}
@media (max-width: 868px) {
  .heder {
    width: 45%;
    margin-left: 25%;
  }
}
@media (max-width: 768px) {
  .loan-img-fluid {
    height: 275px;
  }
  .loan-banner-img1 img {
    height: 50vh;
  }
  .heder {
    width: 60%;
    margin-left: 20%;
  }
  .heder-text {
    font-size: 22px;
    padding: 8px;
  }
  .video-fluid,
  .video {
    height: 25vh;
  }
  .image-main {
    flex-direction: column;
    align-items: center;
  }
  .images {
    padding: 10px;
    width: 60vw;
  }
  .text {
    font-size: 16px;
  }
}
