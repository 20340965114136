.homeloan-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.homeloan-container-main{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}
.hedertext{
    text-align: center;
}

.homeloan-box-main{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}
.homeloan-boxs{
    margin-top: 40px;
    width: 378px;
    height: 500px;
    background-color: rgb(231 241 255);
    /* margin: 0 auto; */
    margin-bottom: 20px;
    /* object-fit: cover; */
    /* box-shadow: 4px 5px 5px #767676; */
}

.homeloan-img-2{
    width: 378px;
    height: 250px;
}

.homeloan-text{
    font-size: 2rem;
    text-align: center;
    margin-top: 20px;
}
.calci-btn-update{
    
}
.homeloanbtn{
    width: 15vw;
    height: 7.5vh;
    font-size: 1.8rem;
    background-color: rgb(33, 133, 255);
    margin-left: 20%;
    margin-top: 40px;
  
}

.homeloan-sections1{
    margin-top: 7%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.txt2{
    width: 28vw;
}

.homeloan-vh-content{
    /* display: flex; */
    /* justify-content:space-around; */
    /* margin-bottom: 10%; */
    margin-top: 5%;
}

.homeloan-video-1{
    width: 35%;
 
}

.homeloan-heding-main{
    /* width: 45%;    */
}

.homeloan-heding{
    font-size: 2.123rem;
    text-align: center;
  
   
}

.homeloan-paragraph{
    font-size: 18px;
    margin-top: 20px;
    color: #888;
}

@media (max-width: 1200px){
    .hedertext{
        width: 35vw;
    }
    .homeloan-boxs{
        /* height: 45vh; */
        /* width: 40vw;    */
    }
    .homeloan-img-2{
        /* width: 40vw; */
    }
    .homeloanbtn{
        width: 60%;  
    }
    .txt2{
        width: 49vw;
    }
}
@media (max-width: 900px){
 
    .homeloan-box-main{
        flex-direction: column;
    }
}
@media (max-width: 868px){
    
    /* .hedertext{
        width: 40%;
    } */
   

    /* .homeloan-boxs{
        height: 55vh;
        width: 70vw;
       
    }
    .homeloan-img-2{
        width: 70vw;
    } */
    .homeloan-text{
        /* font-size: 2.5rem; */
    }
    .homeloanbtn{
        width: 60%;  
    }

    .txt2{
        width: 60vw;
    }
    .homeloan-video-1{
        width: 45%;
     
    }

}

@media (max-width: 768px){
    .homeloan-banner-img img{
        height: 50vh;
    }
    .hedertext{
        font-size: 1.5rem;
        height: 30px;
        width: 260px;
    }
   
    .homeloan-box-main{
        flex-direction: column;
    }
    .homeloan-boxs{
        /* height: 45vh; */
        /* width: 40vw; */
       
    }
    .homeloan-img-2{
        /* width: 40vw; */
        /* height: 20vh; */
    }
    .homeloan-text{
        /* font-size: 1.3rem; */
    }
    .homeloanbtn{
        width: 50%;
        font-size: 22px;  
       
    }
    .txt2{
        width: 50vw;
    }

    .homeloan-vh-content{
        flex-direction:column-reverse;
   
    }
    .hvcontent{
        flex-direction:column;
    }

    .homeloan-video-1{
        width: 80vw;
        margin: 0 auto;
       height: 230px;
    }

    .homeloan-heding-main{
        margin: 0 auto;
        width: 75%;
      margin-bottom: 20px;
    }

}

@media screen and (max-width: 534px){
   
   
    .homeloanbtn{
        font-size: 18px; 
        width: 120px;
    }
    .txt2{
  width: 70vw

    }
}

@media screen and (max-width: 418px){
    .homeloan-boxs{
        /* height: 43vh; */
        /* width: 60vw; */
       
    }
    .homeloan-img-2{
        /* width: 60vw; */
        /* height: 23vh; */
    }
    .homeloan-text{
        /* font-size: 1rem; */
    }
    .txt2{
       height: 5%;
       font-size: 1.2rem;
       padding: 2%;
    }
    .homeloan-paragraph{
        font-size: 18px;
    }
   
}