

.about-container{
    /* max-width: 80%; */
    /* height: 100%; */
    margin: 0 auto;
    padding: 4%;
}
.about-text-heding-main1{
    /* background-color: rgb(54, 135, 233); */
    /* color: #fffafa; */
    /* width: 29%; */
    /* height: 7%; */
    /* border-radius: 15px; */
    /* font-size: 2.8rem; */
    /* margin: 0 auto; */
    text-align: center;
    padding: 5px;
    /* padding-left: 3%; */
}


.ab-heading{
    text-align: center;
    font-size: 3.1rem;
    padding-bottom: 3%;
    padding-top: 3%;
}
.ab-subheading{
    text-align: center;
    padding-bottom: 4%;
    font-size: 2.1rem;
    color: #16427f;
    padding-right: 10%;
    padding-left: 10%;
}
.ab-para{
    /* text-align: center; */
    padding-bottom: 3%;
    font-size: 1.1rem;
    padding-right: 10%;
    padding-left: 10%;
    line-height: 1.5;
}

.about-background-image {
    position: relative;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-size: cover;
    background-position: center;
    

}

.about-background-image::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('../../images//Slider4.jpeg');
    background-size: cover;
    background-position: center;
    opacity: 0.5;
}

.about-para-container{
    position: absolute;
    background-color: white; 
    color: #16427f;
    padding: 20px;
    border-radius: 10px;
    width: 40%;
    /* height: 40vh; */
   
}
.about-para-container h1{
    padding-bottom: 5%;
    padding-top: 4%;
}
.about-para-container p{
    padding-bottom: 5%;
    line-height: 1.1;
}

.service-cards-main h1{
    padding: 2%;
    font-size: 35px;
    text-align: center;
}
.ab-tl{
    align-items: center;
} 



/*...testimonials */
.ab-testimonials-text-heding-main {
    background-color: rgb(54, 135, 233);
    color: #fffafa;
    width: 22%;
    height: 40%;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 5px;
    margin-top: 40px;
    text-align: center;
    font-size: 2rem;
}

.ab-testimonials-box-main {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    column-gap: 5%;
    padding: 2%;
    row-gap: 20px;
}

.ab-testimonials-box {
    width: 300px;
    height: 350px;
    text-align: center;
    background-color: rgb(191, 191, 192);
}

.ab-testimonials-logo {
    width: 40%;
    margin-top: 15px;

}

.ab-testimonials-heding-text {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.ab-testimonials-heding-textp {
    width: 85%;
    margin-left:20px;

}


@media (max-width:1200px) {

    .about-para-container{
        width: 65%;
        /* height: 35vh; */
    }
    .ab-testimonials-text-heding-main
     {
        font-size: 26px;
        width: 26vw;
        padding: 0.5%;
    }
    
    
    
}


@media (max-width:768px) {
    .about-text-heding-main1{
        /* width: 40%; */
        /* font-size: 1.5rem; */
        /* padding-left: 9%; */
    
    }
    .about-para-container{
        width: 65%;
        /* height: 55vh; */
    }
    .about-para-container h1{
        padding-bottom: 5%;
        font-size: 20px;
    }
    .about-para-container p{
    
        line-height: 1.2;
        font-size: 12.5px;
        padding-bottom: 2%;
    }
    /* testemonials */

    .ab-testimonials-text-heding-main
     {
        font-size: 24px;
        width: 36vw;
        padding:0.5%;
     }
     .ab-testimonials-box-main{
        row-gap: 20px;

     }
 
    /* service-card */
    .from-Box{
        width: 310px;
    }
    .img1{
        width: 310px;
    }
}

@media (max-width:600px){
    .ab-testimonials-text-heding-main{
        font-size: 22px;
        width: 56vw;
        padding: 0.5%;
    }
}





























