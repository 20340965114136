.from-Box{
    width: 350px;
    height: 425px;
    background-color: rgb(231 241 255);
    border-radius: 15px; 
    
}

.img1{
    width:350px;
    height: 200px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    margin-bottom: 10px;
}


.text-line{
    width: 20%;
    height: 10px;
    background-color: rgb(54, 135, 233);
    margin-left: 8%;
    margin-bottom: 10px;
}

.text-t2{
    text-align: left;
    padding: 25px;   
}

.text-t1s{
    margin-left: 7%;
}

@media (max-width: 768px){
    .from-Box{
        width: 275px;
        height: 380px;
      
        
    }
    
    .img1{
        width:275px;
        height: 150px;
       
    }
}