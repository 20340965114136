/* Example */
.btn {
  display: inline-block;
  padding: 17px 50px;
  border: 1px solid #16427f;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 30px;
  outline: 0;
  box-shadow: none;
  transition: 0.5s;
  background-color: #16427f;
  color: #f5f5fb;
  text-decoration: none;
  /* border-radius: 10px; */
}
.btn:hover{
  color: #16427f;
  background: transparent;
  border: 1px solid #16427f;
}