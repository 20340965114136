.loginContainer {
  max-width: 400px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.inputGroup {
  margin-bottom: 15px;
}
.form{
  margin: 15px;
}
.label {
  display: block;
  margin-bottom: 5px;
}

.input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.button {
  background-color: #16427f;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

/* Responsive styles */
@media (max-width: 600px) {
  .loginContainer {
    width: 90%;
  }
}
