/* .property-container {
    margin-bottom: 40px;
}
.property-banner-img2 img{
    width: 100%;
    margin-top: -10vh;
    height: 80vh;
}

.property-top-heder-text {
    background-color: rgb(54, 135, 233);
    color: #fffafa;
    width: 22%;
    height: 40%;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 50px;
    margin-top: 40px;
    text-align: center;
    font-size: 2rem;
    padding: 0.5%;
}

.property-heder-text {
    font-size: 2.7rem;
    text-align: center;
    margin-bottom: 50px;
}

.property-Pra-text {
    width: 700px;
}

.property-text-main {
    display: flex;
    justify-content: center;
    column-gap: 5%;
    flex-wrap: wrap;
    row-gap: 20px;
}

.property-Pra-text-information {
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
    line-height: 1.5;
    padding-bottom: 2%;
}

.property-text-address {
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.3;
}

.property-text-address-span {
    font-weight: normal;
}

.property-images {
    width: 650px;
    height: 450px;
}

.property-pic1-main1{
    display: flex;
    column-gap: 5%;
    row-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 60px;
}
.property-pic1 {
    width: 400px;
    height: 300px;
    border-radius: 15px;
}*/

@media (max-width: 768px) {
  .property-banner-img2 img {
    height: 50vh;
  }
  .property-top-heder-text {
    width: 50vw;
    font-size: 1.2rem;
  }
  .property-heder-text {
    font-size: 1.7rem;
    font-weight: 600;
  }
  .property-images {
    width: 350px;
    height: 250px;
  }
  .property-pic1 {
    width: 250px;
    height: 150px;
  }
  .property-Pra-text {
    width: 350px;
  }
  .property-text-address,
  .property-Pra-text-information {
    font-size: 1rem;
  }
}

.property-container {
  width: 100%;
}
.porperty-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 35px 0px;

  position: relative;
  overflow: hidden;
  /* Adjust as needed */
  margin: 0 auto;
}
.property-heder-text {
  flex-wrap: wrap;
  flex-basis: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.property-text-main {
  flex-basis: 50%;
  display: flex;

  width: 100%;
}
.property-Pra-text1 {
  padding: 10px;
  width: 45%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.property-Pra-text-information {
  width: 100%;
  height: 100%;
  font-size: 1.4rem;
  line-height: 1.5;
  padding-bottom: 2%;
}
.property-images {
  display: inline-block;
  width: 650px;
  height: 450px; /* Add some space between items */
  background-color: #ddd;
}
.property-text-address {
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.3;
}

.property-text-address-span {
  font-weight: normal;
}
.property-Pra-text2 {
  padding: 10px;
  width: 55%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.property-pic1-main1 {
  flex-basis: 40%;
  display: flex;
  gap: 50px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.property-pic1-main1::-webkit-scrollbar {
}
.scroll-content {
  width: 1200px; /* Set the width of your content */
  padding: 20px;
  display: flex;
  gap: 0px 80px;
}

.scroll-item {
  display: inline-block;
  width: 425px;
  height: 280px;
  /* margin-right: 100px; Add some space between items */
  background-color: #ddd;
}


@media (max-width: 1200px) {
    .property-top-heder-text {
      width: 40vw;
      padding: 2%;
    }
    .property-text-main {
      flex-direction: column-reverse;
      row-gap: 30px;
      align-items: center;
    }
    .property-text-address {
      text-align: center;
    }
    .property-Pra-text1{
      width: 95%;
    }
    .property-Pra-text2{
        width: 90%;
    }
  }
  
