
.terms-container {
    text-align: center;
    padding-top: 40px;
    
  }
  
  .terms-content {
    margin: 20px;
    text-align: left;
    padding-top: 3%;
    padding-bottom: 5%;
    max-width: 800px; /* Adjusted width */
    font-family: Poppins, sans-serif;
    font-weight: 300;
  }
   .T-head{
    font-size: 25px;
    padding: 5px;
    color: #16427f;
  }
  .T-text{
     font-size: 20px;
     padding: 5px;
  }
  .T-text2{
    font-size: 18px;
    padding: 5px;
 }
  .terms-content p{
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 1.5; 
    text-align: justify;
  }
   
  @media screen and (min-width: 768px) {
    .terms-content {
      max-width: 1000px;
      margin: auto;
    }
  }