/* slider.css */

.slider-container {
    width: 100%;
    margin: auto;
    position: relative;
    padding-bottom: 10%;
  }
  
  .slick-slider {
    text-align: center; /* Center the slides within the slider container */
  }
  
  .slick-track {
    display: flex;
    justify-content: center;
  }

  .content-overlay {
    position: relative;
    z-index: 10;
    width: 100%;
    
  }
  .slider-text {
    position: absolute;
    top: 35%;
    left: 7%;
    color: #16427f;
    font-size: 5rem;
    z-index: 1;
    /* Add any other styles you want */
  }
  .slider-image {
    max-width: 100%;
    height: 80vh;
    position: relative;
    text-align: center;
  
    .img-slide {
        display: block;
        margin: auto; /* Center the image */
        width: 100%;
        height: 100%;
        
    }
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    /* transform: translateY(-50%); */
    font-size: 2em;
    cursor: pointer;
    color: #ffffff;
    border-radius: 50%;
    z-index: 1;
    height: 80px;
    width: 80px;
    text-align: center;
    transition: 1s;
    display: flex;
    justify-content: center;
    align-items: center;


  }

  .arrow:hover{
    background-color: white;
    color: black;
  }
  
  .next {
    right: -20px;
  }
  
  .prev {
    left: -20px;
  }
  @media screen and (max-width: 600px) {
    .slider-image{
        height: 50vh;
    }
    .slider-data{
      font-size: 3rem !important;
    }
    .arrow{
      height: 50px;
      width: 65px;
    }
  }