.invo-text-heding-main {
    background-color: rgb(54, 135, 233);
    color: #fffafa;
    width: 22%;
    height: 40%;
    border-radius: 15px;
    margin: 0 auto;
    margin-bottom: 50px;
    padding: 5px;
    margin-top: 40px;
    text-align: center;
    font-size: 2rem;
    
}

.invo-box-main {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    column-gap: 5%;
    row-gap: 20px;
    padding: 2%;
}

.invo-box {
    width: 300px;
    height: 350px;
    text-align: center;
    border-radius: 10px;
    background-color: #e7f2ff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.invo-logo {
    width: 53%;
    padding-top: 15px;
    padding-bottom: 7%;
}

.invo-heding-text {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.invo-heding-textp {
    width: 85%;
}