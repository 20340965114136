.paginationContainer {
    margin: 20px;
    text-align: center;
  }
  
  .data-list {
    list-style: none;
    padding: 0;
  }
  
  .data-list li {
    border: 1px solid #ddd;
    margin: 5px;
    padding: 10px;
  }
  
  .paginationControls {
    margin-top: 20px;
  }
  
  .paginationButton {
    background-color: #16427f;
    color: #fff;
    border: none;
    padding: 10px 15px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .pagination-button:hover {
    background-color: #16427f;
  }
  
  .pageInfo {
    margin: 0 10px;
    font-weight: bold;
  }
  