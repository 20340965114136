/* Privacy.css */

.privacy-container {
    text-align: center;
    padding-top: 40px;
  }
  
  .privacy-content {
    margin: 20px;
    text-align: left;
    padding-top: 3%;
    padding-bottom: 5%;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    
  }
  .p-head{
    font-size: 25px;
    padding: 5px;
    color: #16427f;
  }
  .p-text{
     font-size: 20px;
     padding: 5px;
  }
  .p-text2{
    font-size: 18px;
    padding: 5px;
 }
  .privacy-content p{
    padding-bottom: 10px;
    font-size: 16px;
    line-height: 1.5; 
    text-align: justify;
  }
  
  @media screen and (min-width: 768px) {
    .privacy-content {
      max-width: 1000px;
      margin: auto;
    }
    
    
  }